<template>
    <div class="grid">
        <div class="col-12">
            <div style="margin-bottom:10px;">
                <a href="/cadastrar-usuario"><Button label="Cadastrar Usuário" class="p-button-primary btn_success" /></a>
            </div>
            <div class="card">
                <div class="grid">
                    <div class="col-9">
                        <h5 class="titulo_pedidos">Usuários</h5>
                    </div>

                </div>
                <Loading v-if="loading" />
                <table class="p-datatable-table" role="rowgroup" v-if="!loading">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <tr role="row">
                            <th style="width:10%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Código</span>
                                </div>
                            </th>
                            <th style="width:30%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Nome</span>
                                </div>
                            </th>
                            <th style="width:40%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">E-mail</span>
                                </div>
                            </th>
                            <th  style="width:20%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Ação</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="" role="rowgroup">
                        <tr v-for="(usuario, index) in usuarios" :key="index" class="" role="row">
                            <td>{{ usuario['id'] }}</td>
                            <td>{{ usuario['nome'] }}</td>
                            <td>{{ usuario['email'] }}</td>
                            <td>
                                <a :href="'/usuario/' + usuario['id']" >
                                    <button class="p-button p-component p-button-icon-only p-button-text" >
                                        <i class="pi pi-search p-button-icon"></i>
                                    </button>
                                </a>
                                <a :href="'/usuario/' + usuario['id']" >
                                    <button class="p-button p-component p-button-icon-only p-button-text"><i class="pi pi-pencil"></i></button>
                                </a>
                                <button style="color: red;" class="p-button p-component p-button-icon-only p-button-text" @click="deletarEmpresa(empresa.id)">
                                    <i class="pi pi-times p-button-icon"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div v-if="quantidadePaginas > 1" class="p-paginator p-component p-paginator-bottom" style="width:100%">
                    <div style="padding: 5px;cursor: pointer;" @click="primeiraPagina()"> Primeira </div>
                    <div style="padding: 5px;cursor: pointer;" @click="anteriorPagina()">Anterior</div>
                    <div style="padding: 5px;cursor: pointer;" v-for="pagina in quantidadePaginas" :key="pagina"
                        @click="paginaAtual(pagina)">{{ pagina }}</div>
                    <div style="padding: 5px;cursor: pointer;" @click="proximaPagina()">Próxima</div>
                    <div style="padding: 5px;cursor: pointer;" @click="ultimaPagina()">Ultima</div>
                </div>
            </div>
        </div>
    </div>

    <Toast position="top-left" /></template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
export default {
    data() {
        return {
            usuarios:[],
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
        }
        
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.representante_id = this.$storage.getStorageSync("user").representante_id
        this.api = new Api();
        this.carregarUsuarios()
        this.loading = false;
    },
     methods:{
        async carregarUsuarios() {
            this.loading = true;
            await this.api.listarUsuarios(this.token, this.empresa_id).then(data => {
                this.usuarios = data.data
                this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        carregarEmpresa(empresa_id){
            this.$router.push({ name: 'empresa', params: { id: empresa_id } })
        },
    },
    components: { Loading }
}
</script>

<style>
table{
    width: 100%;
}
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
th{
    text-align: start;
    padding: 5px 10px; 
    font-weight:normal; 
    border: #ebeef3 solid 1px
}
</style>